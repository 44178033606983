<template>
  <div>
    <b-modal
      title="Room"
      id="modal-prevent-closing"
      v-model="visibleModal"
      @show="resetModal"
      @hidden="resetModal"
    >
      <div class="header">
        <b-dropdown v-if="language" :text="language.name" class="m-md-2">
          <b-dropdown-item
            v-for="(item, index) in listLang"
            :key="index"
            @click="changeLanguage(item)"
          >
            {{ item.name }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <!-- @ok="handleOk" -->
      <!-- <div class="my-3">
        <div class="container-right">
          <div
            v-for="(item, index) in listLang"
            :key="index"
            class="box-lang"
            :class="selectedLang == item.languageId && 'active'"
            @click="selectedLang = item.languageId"
          >
            {{ item.nation }}
          </div>
        </div>
      </div> -->
      <div class="my-3">
        <InputText
          v-model="form.translationList[indexLanguage].name"
          class="input"
          :textFloat="`RoomName`"
          placeholder="RoomName"
          type="text"
          name="name"
          isRequired
          :v="$v.form.translationList.$each[indexLanguage].name"
          :isValidate="$v.form.translationList.$each[indexLanguage].name.$error"
          :selectLang="language"
        />
        <InputText
          v-model="form.locationCode"
          class="input"
          :textFloat="`LocationCode`"
          placeholder="LocationCode"
          type="text"
          name="name"
        />
        <InputText
          v-model="form.sortOrder"
          class="input"
          :textFloat="`SortOrder`"
          placeholder="SortOrder"
          type="number"
          name="name"
          isRequired
          :v="$v.form.sortOrder"
          :isValidate="$v.form.sortOrder.$error"
        />
      </div>
      <template #modal-footer="{ ok }">
        <b-button @click="handleOk"> OK </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { required, requiredIf, minValue } from "vuelidate/lib/validators";
export default {
  name: "ModalAddRoom",
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    room: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      visibleModal: false,
      form: {
        id: 0,
        locationCode: "",
        sortOrder: 0,
        isActive: false,
        translationList: [
          {
            id: 0,
            name: "",
            languageId: 2,
          },
          {
            id: 0,
            name: "",
            languageId: 1,
          },
        ],
      },
      templateForm: {
        id: 0,
        locationCode: "",
        sortOrder: 0,
        isActive: false,
        translationList: [
          {
            id: 0,
            name: "",
            languageId: 2,
          },
          {
            id: 0,
            name: "",
            languageId: 1,
          },
        ],
      },
      selectedLang: 2,
      listLang: [],
    };
  },
  validations() {
    return {
      form: {
        sortOrder: { required, minValue: minValue(1) },
        translationList: {
          $each: {
            name: {
              required: requiredIf(() => {
                const requiredLanguage = this.form.translationList.find(
                  (x) => x.languageId === 2
                );
                return requiredLanguage.name === "";
              }),
            },
          },
        },
      },
    };
  },
  watch: {
    visible(val) {
      this.visibleModal = val;
      if (val === true) {
        if (this.room.id !== undefined) this.form = this.room;

        this.getLanguageOption();
      }
    },
  },
  computed: {
    language() {
      return this.listLang.find((x) => x.languageId === this.selectedLang);
    },
    indexLanguage() {
      return this.form.translationList.findIndex(
        (x) => x.languageId === this.selectedLang
      );
    },
  },
  methods: {
    async getLanguageOption() {
      const getData = await this.$services.master.getLanguageOption();
      if (getData.result == 1) {
        this.listLang = getData.detail;
        this.selectedLang = this.listLang[0].languageId;
      }
    },
    resetModal() {
      this.$v.$reset();
      this.$emit("closeModal");
      this.visibleModal = false;
    },
    handleOk() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        this.selectedLang = 2;
        return;
      } else {
        this.$emit("addRoom", this.form);
        this.form = this.templateForm;
        this.resetModal();
      }
    },
    changeLanguage(v) {
      this.selectedLang = v.languageId;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: flex-end;
}
.container-right {
  display: flex;
  justify-content: right;

  .box-lang {
    padding: 5px 10px;
    margin-right: 5px;
    border: 1px solid black;
    cursor: pointer;

    &.active {
      background: var(--secondary-color);
      color: white;
    }
  }
}
</style>
